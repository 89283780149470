import React from 'react';
import styled from 'styled-components';
import { useIntl } from '@utils/localize';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import Button from '@components/Button';
import Footer from '@components/Footer';
import Navigation, { NavSpacer } from '@components/Navigation';
import SEO from '@components/SEO';
import Space from '@components/Space';
import { Body, Heading } from '@components/type';

const Links = styled.div`
  display: flex;
  align-items: center;

  ${mq.ltsm`
    flex-direction: column;
    width: 100%;
    margin-top: 32px;

    & > a,
    & > a > div {
      width: 100%;
    }

    & > *:not(:last-child) {
      margin-bottom: 16px;
    }
  `}

  ${mq.gtsm`
    margin-top: 56px;

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 8px;
    }
  `}
`;

const Title = styled(Heading)`
  margin-bottom: 16px;
  color: ${colors.B500};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10vh 24px 5vh;
  text-align: center;
`;

const NotFoundPage = ({ banner, navItems }) => {
  const intl = useIntl();

  return (
    <>
      <Navigation banner={banner} navItems={navItems} />
      <main>
        <SEO title="404: Not found" />
        <NavSpacer />
        <Wrapper>
          <Title level={100}>{intl.formatMessage({ id: 'notFound.heading' })}</Title>
          <Body sizes={['small', 'regular']}>
            {intl.formatMessage({ id: 'notFound.description' })}
          </Body>
          <Links>
            <Button appearance="blue" fill href="/">
              {intl.formatMessage({ id: 'notFound.buttons.home' })}
            </Button>
            <Button appearance="blue" fill href="/apply">
              {intl.formatMessage({ id: 'notFound.buttons.apply' })}
            </Button>
            <Button appearance="blue" fill href="/contact">
              {intl.formatMessage({ id: 'notFound.buttons.contact' })}
            </Button>
          </Links>
        </Wrapper>
      </main>
      <Space sizes={['small', 'large']} />
      <Footer />
    </>
  );
};

export default NotFoundPage;
